import React from "react";
import { Fade, Zoom } from "react-reveal";
// import Layout from "../../components/layout";
import Layout from "../components/layout";
import Linkedin from "../images/linkedin.png";

const LeaderTemplate = ({
  pageContext: { name, designation, about, profillePhoto },
}) => {
  //   const [leader, setLeader] = useState("");
  //   const [leaderInfo, setLeaderInfo] = useState({});
  //   useEffect(() => {
  //     //  search leader and set info
  //     if (leader.length > 0) {
  //       let leaderName = leader.split("-").join(" ");

  //       console.log(leaderName);
  //       let searchResult = leaders.filter((leader) => leader.name === leaderName);
  //       console.log(searchResult);
  //       setLeaderInfo(searchResult[0] ? searchResult[0] : "");
  //       if (searchResult.length === 0) {
  //         window.location = "/404";
  //       }
  //     }
  //   }, [leader]);

  //   useEffect(() => {
  //     const urlSearchParams = new URLSearchParams(window.location.search);
  //     const params = Object.fromEntries(urlSearchParams.entries());
  //     if (params.leader) {
  //       setLeader(params.leader);
  //     }
  //   }, []);

  return (
    <Layout isNavWhite={true}>
      {/* <div className="leader-layout-wrapper">
        <div className="person-designation-wrapper">
          <div className="left-image-wrapper">
            <Fade duration={2000}>
              <img src={profillePhoto ? profillePhoto.url : ""} alt="leader" />
            </Fade>
            <div className="right-border"></div>
          </div>
          <div className="right-content-wrapper">
            <div className="name-wrapper">
              <Fade top duration={3000}>
                <h1 className="page-heading bold">{name ? name : ""}</h1>
              </Fade>
              <Fade bottom duration={3000}>
                <p className="title bold">{designation ? designation : ""}</p>
              </Fade>
            </div>
          </div>
        </div>
        <div className="person-information-wrapper">
          <Fade duration={2000} delay={500}>
            <p className="title regular">{about ? about : ""}</p>
          </Fade>
        </div>
      </div> */}
      <div
        // ref={ref}
        className="section m-top-20px "
        id="founder"
      >
        {/* {isVisible && ( */}

        <div className="aboutus-foundersnote-section lead-template-wrapper">
          <div className="image-wrapper lead-template-image-wrapper">
            <div className="inner-wrapper-image">
              <div className="background-strip"></div>
              <img
                src={profillePhoto ? profillePhoto.url : ""}
                alt="Founder"
              ></img>
            </div>
            <div className="info-wrapper">
              <p className="title no-margin">{name ? name : ""}</p>
              <p className="para-text no-margin">
                {designation ? designation : ""}
              </p>
              {/* <a
                href="https://www.linkedin.com/company/goken-america/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="" src={Linkedin}></img>
              </a> */}
            </div>
          </div>
          <div className="content">
            <div className="heading titlecase-text"></div>

            <div className="para m-top-20px">
              <p className="para">{about ? about : ""}</p>
            </div>
          </div>
        </div>
        <br />
        <div className="w-90-wrapper founders-note__paragraph line-height-35 para"></div>

        {/* )} */}
      </div>
    </Layout>
  );
};

export default LeaderTemplate;
